import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/elements/title"
import SubTitle from "../components/elements/subtitle"

const PrivacyPage = ({ data }) => {
  const { markdownRemark } = data
  const { html } = markdownRemark
  return (
    <Layout>
      <SEO title="Privacy" />
      <div className="section">
        <div className="container">
          <Title title="Politica de confidențialitate" />
          <SubTitle subtitle="Suntem foarte încântați că ați arătat interes pentru compania noastră." />
          <div
            className="content privacy"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PrivacyQuery {
    markdownRemark(frontmatter: { category: { eq: "privacy" } }) {
      html
    }
  }
`
export default PrivacyPage
